
  import {Component, Vue} from 'vue-property-decorator';
  import OfficesSelector from '@/components/molecules/selector/OfficesSelector.vue';
  import {EmptyInquiryRegisterRequest, InquiryRegisterRequest} from '@/types/inquiry/Inquiry';
  import {InquiryRegisterChecker} from '@/validator/inquiry/InquiryRegisterChecker';
  import {loginModule} from '@/stores/authentication/Account';
  import moment from 'moment';

  @Component({
    components: {
      OfficesSelector,
    },
  })
  export default class InquiryForm extends Vue {
    // data
    form: InquiryRegisterRequest = {...this.request};

    messages: string[] = [];

    // computed
    get request(): InquiryRegisterRequest {
      if (sessionStorage.hasOwnProperty('inquiry-form')) {
        return JSON.parse(sessionStorage.getItem('inquiry-form') as string);
      }
      return EmptyInquiryRegisterRequest;
    }

    get assessorNumber(): string {
      return loginModule.assessorNumber;
    }

    get hasError(): boolean {
      return this.messages.length > 0;
    }

    // methods
    confirm(): void {
      this.messages = [];

      const validator = new InquiryRegisterChecker(this.form);
      if (validator.hasErrors) {
        this.messages = validator.getMessages;
        return;
      }

      this.form.inquiresAt = moment().toDate();

      sessionStorage.setItem('inquiry-form', JSON.stringify(this.form));

      this.$router.push({name: 'inquiryConfirm'});
    }

    // lifecycle hooks
    created(): void {
      if (sessionStorage.hasOwnProperty('inquiry-form')) {
        sessionStorage.removeItem('inquiry-form');
      }
    }
  }
