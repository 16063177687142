import {required, ValidationResult, Validator} from '@/types/validation/ValidationResult';
import {InquiryRegisterRequest} from '@/types/inquiry/Inquiry';

export class InquiryRegisterChecker {
  result: ValidationResult;

  constructor(result: InquiryRegisterRequest) {
    const checker = new ValidationResult(InquiryRegisterValidator);
    checker.validate(result);
    this.result = checker;
  }

  get hasErrors(): boolean {
    return this.result.hasErrors;
  }

  get getMessages(): string[] {
    return this.result.getMessages();
  }
}

export const InquiryRegisterValidator: Validator[] = [
  {
    name: 'title',
    constraints: [
      {method: required, message: '件名を入力してください'},
    ],
  },
  {
    name: 'content',
    constraints: [
      {method: required, message: '内容を入力してください'},
    ],
  },
];
